<template>
  <div class="body">
    <!-- 配货单 -->
    <div v-if="isOut" class="print content" style="text-align: center; color: black">
      <h2>{{ getOrganization }}配货单</h2>
      <br />
      <div class="float" style="margin-bottom: 10px">
        <span
          >收货客户：{{
            !!printData.orderDetail ? printData.orderDetail.addressDTO.receiverName : printData.baseInfo.detail.client
          }}</span
        >
        <!-- <span
          >发往物流：{{
            !!printData.orderDetail &&
            !!printData.orderDetail.addressDTO.receiverAddr &&
            !!printData.orderDetail.addressDTO.receiverAddrDetail
              ? printData.orderDetail.addressDTO.receiverAddr + printData.orderDetail.addressDTO.receiverAddrDetail
              : printData.baseInfo.detail.receiverAddrDetail || ''
          }}
        </span> -->
        <span>日期：{{ getCurrentTime }}</span>
        <span>编号：{{ printData.baseInfo.detail.stockNumber }}</span>
      </div>
      <table class="print-table">
        <tr class="print-tr">
          <th class="print-tr-th">序号</th>
          <th class="print-tr-th">产品库位编码</th>
          <th class="print-tr-th">产品名称与型号</th>
          <th class="print-tr-th">规格</th>
          <th class="print-tr-th">数量</th>
          <th class="print-tr-th">单位</th>
        </tr>
        <!-- 表格行1 -->
        <tr v-for="(data, index) in printData.data.records" :key="index" class="print-tr">
          <td class="print-tr-td">
            {{ index + 1 }}
          </td>
          <td class="print-tr-td">
            {{ getWareHouseName(data.warehouseName) }}
          </td>
          <td class="print-tr-td">
            {{ data.isSingle ? `${data.productName}` : `${data.title}` }}
          </td>
          <td class="print-tr-td">
            {{ getUnit(data) }}
          </td>
          <td class="print-tr-td">
            {{ getConvertToBoxNum(data) }}
          </td>
          <td class="print-tr-td">
            {{ getUnitName(data) }}
          </td>
        </tr>
        <!-- 合记 -->
        <tr class="print-tr">
          <td class="print-tr-td">合计</td>
          <td colspan="3"></td>
          <td>{{ amountTo }}</td>
          <td :colspan="1"></td>
        </tr>
        <tr class="print-tr-hasPoint">
          <td class="print-tr-td">备注</td>
          <td class="print-tr-td" :colspan="5">
            <span class="hasPoint-text">
              {{
                !!printData.orderDetail
                  ? printData.orderDetail.orderDTO.orderMemo
                  : printData.baseInfo.detail.remark || ''
              }}
            </span>
          </td>
        </tr>
      </table>
      <br />
      <v-row style="color: black">
        <v-col> 下单：{{ printData.baseInfo.detail.creator || '' }} </v-col>
        <v-col>配货：</v-col>
        <v-col>出货：</v-col>
      </v-row>
    </div>
    <!-- 入库单 -->
    <div v-if="isIn" class="print content" style="text-align: center; color: black">
      <h2>{{ getOrganization }}入库单</h2>
      <br />
      <div class="float" style="margin-bottom: 10px">
        <span></span>
        <span>日期：{{ getCurrentTime }}</span>
        <span>编号：{{ printData.baseInfo.detail.stockNumber }}</span>
      </div>
      <table class="print-table">
        <tr class="print-tr">
          <th class="print-tr-th">序号</th>
          <th class="print-tr-th">产品库位编码</th>
          <th class="print-tr-th">产品名称与型号</th>
          <th class="print-tr-th">规格</th>
          <th class="print-tr-th">数量</th>
          <th class="print-tr-th">单位</th>
        </tr>
        <!-- 表格行1 -->
        <tr v-for="(data, index) in printData.data.records" :key="index" class="print-tr">
          <td class="print-tr-td">
            {{ index + 1 }}
          </td>
          <td class="print-tr-td">
            {{ getWareHouseName(data.warehouseName) }}
          </td>
          <td class="print-tr-td">
            {{ data.isSingle ? `${data.productName}` : `${data.title}` }}
          </td>
          <td class="print-tr-td">
            {{ getUnit(data) }}
          </td>
          <td class="print-tr-td">
            {{ getConvertToBoxNum(data) }}
          </td>
          <td class="print-tr-td">
            {{ getUnitName(data) }}
          </td>
        </tr>
        <tr class="print-tr">
          <td class="print-tr-td">合计</td>
          <td colspan="3"></td>
          <td>{{ amountTo }}</td>
          <td :colspan="1"></td>
        </tr>
      </table>
      <br />
      <v-row style="color: black">
        <v-col> 开单员：{{ printData.baseInfo.detail.creator || '' }} </v-col>
        <v-col>经手人：</v-col>
        <v-col>制表人：{{ ckzg || '' }}</v-col>
        <v-col>审核人：</v-col>
      </v-row>
    </div>
    <!-- 领料单  -->
    <div v-if="isInteralOut" class="print content" style="text-align: center; color: black">
      <h2>{{ getOrganization }}领料单</h2>
      <br />
      <br />
      <span class="float-left">收件客户：{{ printData.baseInfo.detail.takeUserName }}</span>
      <span class="float-center">日期：{{ getCurrentTime }}</span>
      <span class="float-right">编号：{{ printData.baseInfo.detail.materialNumber }}</span>
      <br />
      <table class="print-table">
        <tr class="print-tr">
          <th class="print-tr-th">序号</th>
          <th class="print-tr-th">产品库位编码</th>
          <th class="print-tr-th">产品名称与型号</th>
          <th class="print-tr-th">规格</th>
          <th class="print-tr-th">数量</th>
          <th class="print-tr-th">单位</th>
          <th v-if="hasLastPoint" class="print-tr-th">备注</th>
        </tr>
        <!-- 表格行1 -->
        <tr v-for="(data, index) in printData.data.records" :key="index" class="print-tr">
          <td class="print-tr-td">
            {{ index + 1 }}
          </td>
          <td class="print-tr-td">
            {{ getWareHouseName(data.warehouseName) }}
          </td>
          <td class="print-tr-td">
            {{ data.isSingle ? `${data.productName}` : `${data.title}` }}
          </td>
          <td class="print-tr-td">
            {{ getUnit(data) }}
          </td>
          <td class="print-tr-td">
            {{ getConvertToBoxNum(data) }}
          </td>
          <td class="print-tr-td">
            {{ getUnitName(data) }}
          </td>
          <!-- <td v-if="hasPoint(data)" class="print-tr-td">
            {{ convertToBoxAndRemainder(data.quantity, data) }}
          </td> -->
        </tr>
        <!-- 合记 -->
        <tr class="print-tr">
          <td class="print-tr-td">合计</td>
          <td colspan="3"></td>
          <td>{{ amountTo }}</td>
          <td :colspan="1"></td>
        </tr>
        <tr class="print-tr-hasPoint">
          <td class="print-tr-td">备注</td>
          <td class="print-tr-td" :colspan="5">
            <span class="hasPoint-text">
              {{
                printData.baseInfo.detail.remark || ''
              }}
            </span>
          </td>
        </tr>
      </table>
      <br />
      <v-row style="color: black">
        <!-- <v-col>制表人(下单)：{{ ckzg || '' }}</v-col> -->
        <v-col> 下单：{{ printData.baseInfo.detail.sysTakeUserName || '' }} </v-col>
        <v-col>配货：</v-col>
        <v-col>出货：</v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import RoleApi from '@/api/system/role'
import StockApi from '@/api/product/stock'
import MaterialApi from '@/api/product/materialBill'

export default {
  components: {},
  data: () => ({
    printData: {
      baseInfo: {
        detail: {},
      },
      data: {
        records: [],
      },
    },
    isIn: false,
    isOut: false,
    isInteralOut: false,
    ckzg: '',
    hasLastPoint: false,
    amountTo: 0,
  }),

  computed: {
    getCurrentTime() {
      // 生成当前时间，20xx年03月04日15时01分多少秒
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const hour = date.getHours().toString().padStart(2, '0')
      const minute = date.getMinutes().toString().padStart(2, '0')

      return `${year}-${month}-${day}  ${hour}:${minute}`
    },
    getOrganization() {
      if (JSON.parse(localStorage.getItem('companyInfo'))) {
        return JSON.parse(localStorage.getItem('companyInfo')).organization
      }

      return ''
    },
  },
  mounted() {
    Promise.all([this.getWarchouseInfo()]).then(() => {

      if (this.isIn || this.isOut) {
        this.addStockPrintCount()
      } else if(this.isInteralOut){
        this.addMaterial1PrintCount()
      }

      this.printData.data.records.forEach(item => {
        let unitcout = this.getConvertToBoxNum(item)
        this.amountTo = this.amountTo + unitcout
      })

      // 对printData.data.records中的item根据productName排序
      this.printData.data.records.sort((a, b) => {
        return a.productName.localeCompare(b.productName)
      })

    })
    setTimeout(() => {
      window.print()
    }, 1500)
  },
  methods: {
    getWareHouseName(name) {
      if (name === null) {
        return ''
      }

      // 如果有用/ 分隔 就取最后一个
      if (name.indexOf('/') !== -1) {
        const arr = name.split('/')

        return arr[arr.length - 1]
      }
    },
    //初始化打印的订单信息
    getWarchouseInfo() {
      RoleApi.getWarehouseManageUserName().then(res => {
        this.ckzg = res.data.data
      })
      const PrintData = localStorage.getItem('printData')
      this.printData = JSON.parse(PrintData)
      if (this.printData.baseInfo.isIn) {
        this.isIn = true
        // console.log("isIn",this.isIn)
      }
      if (!this.printData.baseInfo.isIn && this.printData.baseInfo.detail.sourceMaterialId !== null) {
        this.isInteralOut = true
        // console.log("isInteralOut",this.isInteralOut)
      }

      if (!this.printData.baseInfo.isIn && this.printData.baseInfo.detail.sourceMaterialId === null) {
        this.isOut = true
        // console.log("isOut",this.isOut)
      }
    },
    // 打开界面时调去订单打印次数增加接口
    addStockPrintCount() {
      StockApi.addStockPrintCount(this.printData.baseInfo.detail.id) // 已全局进行了接口异常出来，直接调用接口不做额外异常处理
    },
    getUnit(item) {
      const { unitId } = item
      const { unitConversion } = item

      // 根据unitId找到对应的单位
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)
      if (selectedUnit.isBase) {
        return selectedUnit.unitName
      }

      return selectedUnit.name
    },
    getUnitName(item) {
      const { unitId } = item
      const { unitConversion } = item

      // 根据unitId找到对应的单位
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)

      return selectedUnit.unitName
    },
    getConvertToBoxNum(item) {
      const { unitId } = item
      const { unitConversion, quantity } = item

      // 根据unitId找到对应的单位
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)
      // 计算整箱数和尾数
      const boxes = Math.floor(quantity / selectedUnit.unitQuantity)
      return boxes
    },
    hasPoint(item) {
      const { unitId } = item
      const { unitConversion, quantity } = item

      // 根据unitId找到对应的单位
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)

      // 计算整箱数和尾数
      const remainder = quantity % selectedUnit.unitQuantity

      if (remainder > 0) {
        this.hasLastPoint = true
        return true
      }
      return false
    },
    convertToBoxAndRemainder(totalQuantity, item) {
      const { unitId } = item
      const { unitConversion } = item

      // 根据unitId找到对应的单位
      const selectedUnit = unitConversion.find(unit => unit.id === unitId)

      // 如果isBase为true，则直接返回基准单位的数量
      if (selectedUnit.isBase) {
        return `${totalQuantity}${selectedUnit.unitName}`
      }

      // 计算整箱数和尾数
      const boxes = Math.floor(totalQuantity / selectedUnit.unitQuantity)
      const remainder = totalQuantity % selectedUnit.unitQuantity

      // 构建结果字符串
      let result = ''

      if (remainder > 0) {
        result += `${remainder}个尾数`
      }

      return result
    },
    //增加material1打印次数
    addMaterial1PrintCount() {
      MaterialApi.addMaterialPrintCount(this.printData.baseInfo.detail.id) // 已全局进行了接口异常出来，直接调用接口不做额外异常处理
    },
  },
}
</script>

<style>
/* 白色背景 */
.theme--light.v-application {
  background-color: #fff !important;
  margin: 0;
  padding: 0;
}

.float {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
}

.print-table {
  border-collapse: collapse;
  width: 100%;
  color: black;
  font-size: 13px;
  border: 1px solid black;
}
.print-tr-hasPoint {
  text-align: left;
  height: 6em;
}
.hasPoint-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
.print-tr {
  border: 1px solid black;
}
.print-tr-th,
.print-tr-td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  color: black;
  font-size: 13px;
}

.print-th {
  background-color: #f2f2f2;
  color: black;
  font-size: 13px;
}

/* 自定义页面样式 */
.content {
  width: 280mm;
  /* height: 297mm; */
  padding: 20px;
  visibility: visible;
  /* position: absolute; */
  /* left: 0;
  top: 0; */
}
.body {
  /* margin: 0;
  padding: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}
@media print {
  @page {
    size: auto;
    margin: 0; /* 取消页面的默认边距 */
    size: landscape;
  }
}
body {
  visibility: hidden;
}
</style>
